import { BusinessInfo } from '@wix/bookings-uou-types/dist/src';
import { BookingPolicyDto } from '../../types/shared-types';

export interface PolicySectionViewModel {
  cancellationPolicy?: string;
}
export const policySectionViewModelFactory = (
  bookingPolicyDto: BookingPolicyDto,
  businessInfo: BusinessInfo,
  isCancellationPolicyChangeApiEnabled: boolean,
): PolicySectionViewModel => {
  return {
    cancellationPolicy: isCancellationPolicyChangeApiEnabled
      ? bookingPolicyDto.cancellationPolicy
      : businessInfo?.cancellationPolicy,
  };
};
