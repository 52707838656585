import {
  CatalogServiceDto,
  ConferencePlatform,
} from '@wix/bookings-uou-types/dist/src';

export interface TitleAndTaglineViewModel {
  title: string;
  tagline?: string;
  onlineProvider?: ConferencePlatform;
  availableOnline?: boolean;
}

export const titleAndTaglineViewModelFactory = (
  serviceDto: CatalogServiceDto,
  isIncludeConferenceOptionEnabled: boolean,
): TitleAndTaglineViewModel => {
  const viewModel: TitleAndTaglineViewModel = {
    title: serviceDto.info?.name,
    tagline: serviceDto.info?.tagLine,
  };
  if (isIncludeConferenceOptionEnabled) {
    viewModel.availableOnline =
      !!serviceDto.schedulePolicy?.includeConferenceOption;
  } else {
    viewModel.onlineProvider =
      serviceDto.schedulePolicy?.conferenceProvider?.platform;
  }
  return viewModel;
};
